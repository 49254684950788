import React from 'react';

type DynamicInputProps<T extends string, P> = {
  components: {[key in T]: React.ComponentType<P>};
  type: T;
  props: P;
};
export const DynamicInput = <T extends string, P>({components, type, props}: DynamicInputProps<T, P>) => {
  // TODO - better way to identify props that belong on the element
  const {markdown, default: _, ...inputProps} = props as any;
  return React.createElement(components[type], inputProps);
};

export type ExtraSelectProps = {
  options: Array<{label: string; value: string; group?: string}>;
};
export type ExtraTextProps = {
  pattern?: string;
};
export type ExtraFileProps = {
  accept?: string;
  value: React.ComponentPropsWithoutRef<'input'>['value'] | {
    path: string;
    label: string;
    mimeType: string;
    dataType: 'file';
  };
};

export const inputConfigs = {
  Select: [
    {
      type: 'TextInput' as const,
      name: 'options',
      label: 'Options',
      required: true,
      transformValue: (value: string | undefined) => {
        const options = (value || '').split(',').map(option => {
          const match = option.match(/^ *(\[([^\]]+)\])? *(.+)$/);
          return match ? {label: match[3], value: match[2] || match[3]} : {};
        });

        return options;
      },
      renderValue: (value: any) => !(value instanceof Array)
        ? value
        : value.map(option => option.label === option.value
          ? option.label
          : `[${option.value}] ${option.label}`
        ).join(', ')
      ,
      help: "comma separated: 'option one, option two' or with value '[one] option one, [two] option two'"
    }
  ],
  File: [
    {
      type: 'TextInput' as const,
      name: 'accept',
      label: 'File Types',
      required: false,
      help: "comma separated list of types. eg: image/png, image/jpeg, .doc, .docx, image/*, .pdf"
    },
    {
      type: 'TextInput' as const,
      name: 'default',
      label: 'Default Value',
      required: false,
      help: 'File default value',
    },
  ],
  TextInput: [
    {
      type: 'TextInput' as const,
      name: 'pattern',
      label: 'Validation',
      required: false,
      help: "Regular expression to validate this value."
    },
    {
      type: 'TextInput' as const,
      name: 'default',
      label: 'Default Value',
      required: false,
      help: 'tip: template defaults based on other fields like this: ' +
        'Researcher profile: <%= profileName %>',
    },
    {
      type: 'Checkbox' as const,
      name: 'markdown',
      label: 'Support Markdown',
      required: false,
    }
  ],
  TextArea: [
    {
      type: 'TextArea' as const,
      name: 'default',
      label: 'Default Value',
      required: false,
      help: 'tip: template defaults based on other fields like this: ' +
        'Researcher profile: <%= profileName %>',
    },
    {
      type: 'Checkbox' as const,
      name: 'markdown',
      label: 'Support Markdown',
      required: false,
    }
  ]
};
